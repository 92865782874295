<template>
    <div class="live_bg">
        <div class="class_head">
            <img src="../assets/sharelogo.png" class="share_logo">
            <div class="share_title">听股票</div>
            <div class="down_app" @click="downloadAppAction">下载App</div>
        </div>
        <van-sticky>
            <div class="all_bg" ref="liveTitle">
                <div class="live_nav">
                    <div class="live_nav_title">
                        <span>{{ this.m3u8.roomTitle }}</span>
                        <img src="../assets/icon_live_numberofpeople_999_24@2x.png">
                        <span class="room_count">{{ this.onlineCount }}</span>
                    </div>
                </div>
                <div class="course_video">
                    <!-- 使用 vue-aliplayer-v2 组件播放视频，当有可用的 m3u8 地址时 -->
                    <vue-aliplayer-v2 v-if="m3u8.m3u8" :source="m3u8.m3u8" ref="player" :options="options" :style="videoHeight"
                                @error="handlePlayerError" @canplay="handlePlayerReady"/>
                    <!-- 当没有 m3u8 地址时，使用 iframe 加载视频 -->
                    <iframe v-else :src="m3u8.link" class="live_iframe" style="border: none; overflow: hidden;" allowfullscreen></iframe>
                </div>


              <div class="live_tabBar">
                    <div class="live_title">聊天</div>
                </div>
                <ccsocket :imRoomId="imRoomId" :gonggaoStr="gongGaoStr" :path="path" isPayLive="true" offSetTop="296" ref="ccwebsocket">
                </ccsocket>
            </div>
        </van-sticky>
        <div class="get_app_button" @click="downloadAppAction">前往App发言</div>
    </div>
</template>
<script>
import ccsocket from './ccsocket.vue'
import VueAliplayerV2 from "vue-aliplayer-v2";
import { requestDataTGPMethod, isDev, generateUUID } from "@/utils/appManager"
export default {
    name: "ShareLive",
    components: { VueAliplayerV2 ,ccsocket},
    data() {
        return {
            canPlay: true,
            videoProgressText: "老师连麦中…",
            isFollow: false,
            hisUrl: '',
            m3u8: '',
            videoHeight: "height:200px",
            options: {
                // source:'//player.alicdn.com/video/aliyunmedia.mp4',
                isLive: true, //切换为直播流的时候必填
                format: 'm3u8', //切换为直播流的时候必填
                controlBarVisibility: "click",
                autoplay: true,
            },
            inputState: true,
            showMenuRet: true,
            firstClickRet: false,
            ccisPlaying: true,
            isFullScreenRet: false,
            isAllScreen: false,
            //聊天
            path: 'wss://im.tinggupiao.com.cn/websocket',
            roomId: '-1',
            imRoomId: '-1',
            onlineCount: '--',
            gongGaoStr: '--',
            imname: '用户'
        };
    },
    methods: {
        otherPlaceLoginAction() {
            console.log("异地登录")
        },
        changeMediaAction() {
            // 切流
            this.$refs.VueAliplayerV2.pause();
            this.requestLiveData();
        },
        changeRoomCountAction(num) {
            this.onlineCount = num;
        },
        //下一场 付费
        nextPayRoomAction(data) {
            // 下一场 付费

        },
        //下一场 免费
        nextFreeRoomAction(data) {

        },
        //无下一场
        nextNoRoomAction() {
            NSLog("ccccccc+++++");
        },
        checkVideoUrl() {
            if (this.canPlay == false) {
                this.m3u8.m3u8 = "";
                setTimeout(() => {
                    this.requestLiveData();
                    this.checkVideoUrl();
                }, 180000);
            }
        },
        shareAction() {
        },
        canPlayAction(e) {
            //console.log("ccCanPlay");
            //console.log(e);
            this.canPlay = true;
            this.videoProgressText = "老师连麦中…";
        },
        errorAction(e) {
            //console.log("ccerror");
            //console.log(e)
            this.canPlay = false;
            this.videoProgressText = "老师连麦中…";
            this.checkVideoUrl();
        },
        //播放操作 start
        backToCurrentAction() {
            // this.$refs.VueAliplayerV2.replay();
            this.$refs.VueAliplayerV2.loadByUrl(this.m3u8.m3u8);
            this.ccisPlaying = true;
            this.firstClickRet = true;
        },
        //播放操作
        fullScreenAction() {
            //console.log("全屏");
            this.isAllScreen = !this.isAllScreen;
            if (this.isAllScreen) {
                this.$refs.VueAliplayerV2.requestFullScreen();
            } else {
                this.$refs.VueAliplayerV2.cancelFullScreen()
            }
            setTimeout(() => {
                var bg = document.getElementsByClassName("video_bg")[0];
                bg.style.height = "200px";
                this.showMenuRet = true;
            }, 300);
        },
        playOrPauseAction() {

            if (this.ccisPlaying) {
                //console.log("暂停");
                this.$refs.VueAliplayerV2.pause();
            } else {
                this.$refs.VueAliplayerV2.play();
                //console.log("播放");
            }
            this.ccisPlaying = !this.ccisPlaying;
            this.firstClickRet = true;
        },
        clickVideoAction() {
            this.showMenuRet = !this.showMenuRet;
            if (!this.firstClickRet) {
                ////console.log("play");
                this.$refs.VueAliplayerV2.play();
                this.ccisPlaying = true;
            }

            this.firstClickRet = true;
        },
        requestLiveData() {
            //请求详情
            requestDataTGPMethod(
                "requestData",
                "v4/im/getBaseInfoByRoomId",
                { room_id: this.roomId },
                "getLiveDataSuccess",
                "0"
            );
        },
        downloadAppAction() {
            // 通用协议地址
            // iOS 下载地址
            var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
            // Android 下载地址
            var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"

            var commSchemeUrl = 'https://api.tinggupiao.com.cn/';
            let u = navigator.userAgent
            var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIos) {
                commSchemeUrl = commSchemeUrl + encodeURIComponent('action:9999?url=127.0.0.1&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + "}")
                //console.log(commSchemeUrl);
                //
                //console.log(u.toLocaleLowerCase())
                if (u.toLocaleLowerCase().match("qq")) {
                    commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=" + encodeURIComponent('scxListenStock://' + 'action:9999?haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + "}");
                } else {

                }
                window.location.href = commSchemeUrl;
            } else {
                //console.log(commSchemeUrl);
                commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&android_schema=" + encodeURIComponent('scxlistenstock://' + 'action:9999?haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + "}");
                window.location.href = commSchemeUrl;
                //console.log(commSchemeUrl);
            }
            // 3秒后没打开，直接跳转下载页面
            var appDownload = setTimeout(function () {
                //console.log("下载app");
                if (isIos) {
                    window.location.href = iOSDownloadUrl;
                } else {
                    window.location.href = androidDownloadUrl;
                }
            }, 3000);
            if (!isIos) {
                document.addEventListener('visibilitychange webkitvisibilitychange', function () {
                    // 页面隐藏，打开app，清除下载
                    if (document.hidden || document.webkitHidden) {
                        clearTimeout(appDownload)
                    }
                })
                window.addEventListener('pagehide', function () {
                    clearTimeout(appDownload)
                })
            }
        }
    },
    mounted() {
        this.roomId = this.$route.query.roomId;
        this.imRoomId = this.$route.query.imRoomId;
        console.log(this.$route.query)
        this.requestLiveData();
    },
    created() {
        window["getLiveDataSuccess"] = (data) => {
            //console.log(data);
            if (data == "error") {
                return;
            }
            this.m3u8 = data.data.m3u8;
            document.title = this.m3u8.roomTitle;
            if (this.m3u8.roomTitle == undefined) {
                this.m3u8.roomTitle = "暂无直播";
            }
            if (data.data.announcement.annTitle.length == 0 && data.data.announcement.annContent.length == 0) {

            } else {
                this.gongGaoStr = data.data.announcement.annTitle + ": " + data.data.announcement.annContent;
            }
            if (!data.data.running) {
                var errorDiv = document.getElementsByClassName("prism-ErrorMessage")[0];
                errorDiv.style.display = "none";
            }
            this.$refs.VueAliplayerV2.player.ready();
        };
    }
};
</script>
<style scoped  lang="scss" >
.class_head {
    position: relative;
    background-color: #ffffff;

    .share_logo {
        margin-top: 16px;
        margin-left: 16px;
        width: 44px;
    }

    .share_title {
        position: absolute;
        left: 72px;
        top: 0;
        width: 100px;
        height: 72px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 76px;
    }

    .down_app {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 84px;
        height: 32px;
        background-color: #EF0923;
        border-radius: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        text-align: center;
    }
}

.live_bg {
    position: relative;
    height: 100%;

    .live_nav {
        position: relative;
        width: 100%;
        height: 44px;
        background-color: #ffffff;

        .back {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 44px;
            height: 44px;

            img {
                position: absolute;
                top: 15px;
                left: 10px;
                width: 12px;
                height: 18px;
            }
        }

        .live_nav_title {
            // background-color: cyan;
            padding-top: 13px;
            margin-left: 10px;
            margin-right: 44px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;

            .room_count {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 20px;
            }

            img {
                width: 24px;
                vertical-align: top;
            }
        }

        .share_img {
            position: absolute;
            top: 12px;
            right: 10px;
            ;
            width: 25px;
        }
    }

    .course_video {
        width: 100%;
        background-color: black;
        position: relative;
        top: 0px;

        .video_error {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            font-size: 25px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: black;
            line-height: 200px;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
            }

            p {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 30px;
                transform: translateY(10px);
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 30px;
            }
        }

        /deep/.prism-ErrorMessage {
            width: 0;
        }

        .video_layer {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 100px;
            transform: translate(-50%, -50%);
            z-index: 13;
        }

        video {
            width: 100%;
            height: 200px;
        }

        .video_bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background-color: green;
            z-index: 200;

            .bottom_control {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 72px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
                border-radius: 1px;

                .cc_pause_button {
                    position: absolute;
                    left: 24px;
                    top: 32px;
                    width: 24px;
                }

                .cc_play_button {
                    position: absolute;
                    left: 24px;
                    top: 32px;
                    width: 24px;
                }

                .back_current {
                    position: absolute;
                    left: 75px;
                    top: 32px;
                    display: inline-block;
                    padding: 2px 10px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 20px;
                    background: #EF0923;
                    border-radius: 12px;
                }

                .fullButton {
                    position: absolute;
                    width: 24px;
                    top: 33px;
                    right: 20px;
                    // background-color: red;
                }
            }
        }

        /deep/ .prism-tooltip {
            bottom: 2000px;
            background-color: black;
            color: black;
        }

        /deep/ .volume-icon {
            display: none;
        }

        /deep/ .prism-info-display {
            padding: 0;
        }

        /deep/ .prism-setting-btn {
            display: none;
        }

        /deep/ .prism-cc-btn {
            display: none;
        }

        /deep/ .prism-player {
            z-index: 1;

            .prism-big-play-btn {
                display: none;
                top: 50%;
                left: 50% !important;
                transform: translate(-50%, -50%);
            }

            height: none;

            // .prism-controlbar {
            //   z-index: -1;
            //   display: none;

            //   .prism-controlbar-bg {
            //     display: inline-block;
            //   }

            //   .prism-cc-btn {
            //     display: none;
            //   }

            //   .prism-live-display {
            //     display: none;
            //     visibility: hidden;
            //   }
            // }
        }
    }

    .live_tabBar {
        position: relative;
        background-color: #ffffff;
        // background-color: cyan;
        height: 52px;

        .live_title {
            position: absolute;
            left: 15px;

            // margin-left: 15px;
            // margin-right: 80px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 52px;
        }

        .teacher_head {
            float: right;
            margin-right: 10px;
            margin-top: 8px;
            width: 32px;
            border-radius: 16px;
        }

        .teacher_name {
            float: right;
            display: inline-block;
            margin-right: 115px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 52px;
        }

        .unattend {
            position: absolute;
            top: 10px;
            right: 16px;
            // margin-top: 24px;
            // margin-right: 16px;
            width: 74px;
            height: 32px;
            background-color: #f8f8f8;
            border-radius: 16px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ef0923;
            line-height: 32px;
            text-align: center;

            .add {
                float: left;
                margin-left: 15px;
                margin-top: 10px;
                width: 12px;
                height: 12px;
            }

            span {
                margin-left: 5px;
                float: left;
            }
        }

        .attend {
            position: absolute;
            top: 10px;
            right: 16px;
            width: 74px;
            height: 32px;
            background-color: #f8f8f8;
            border-radius: 16px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #c8c8c8;
            line-height: 32px;
            text-align: center;
        }
    }

    .room_middle_bg {
        background-color: #ffffff;
    }

    .room_info {
        position: relative;
        height: 40px;
        background-color: rgba($color: #FF7200, $alpha: 0.08);

        .info_head_img {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 13px;
            height: 13px;
        }

        .room_info_content {
            padding-top: 10px;
            margin-right: 28px;
            margin-left: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 200;
            color: #FF7200;
            line-height: 21px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .room_down_img {
            position: absolute;
            top: 15px;
            right: 10px;
            width: 14px;

        }
    }

    .room_info_all {
        position: relative;
        padding-bottom: 10px;
        background-color: rgba($color: #FF7200, $alpha: 0.08);

        .info_head_img {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 13px;
            height: 13px;
        }

        .room_info_content {
            padding-top: 10px;
            margin-right: 28px;
            margin-left: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 200;
            color: #FF7200;
            line-height: 21px;
        }

        .room_down_img {
            position: absolute;
            bottom: 15px;
            right: 10px;
            width: 14px;

        }
    }

    .live_message {
        // background-color: cyan;
        padding-bottom: 64px;
        // background-color: cyan;

        .message_time {
            width: 100%;
            text-align: center;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #B1B1B1;
            line-height: 26px;
        }

        .message_gonggao {
            margin-left: 16px;
            margin-right: 16px;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #656C7A;
            line-height: 20px;
        }

        .message_people {
            position: relative;
            min-height: 24px;
            // background-color: yellow;

            .peple_content {
                display: inline-block;
                padding-left: 16px;
                padding-right: 16px;
                margin-top: 16px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 25px;

                .people_name {
                    display: inline-block;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 21px;
                }
            }
        }
    }

    /deep/ .have_new_voice {
        position: fixed;
        bottom: 0;
        left: 50%;
        width: 112px;
        height: 38px;
        background: #FFF4EB;
        border-radius: 40px;
        transform: translateX(-50%);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF7200;
        line-height: 38px;
        text-align: center;

        img {
            width: 14px;
            vertical-align: middle;
        }
    }

    .get_app_button {
        position: fixed;
        z-index: 1000;
        left: 50%;
        bottom: 100px;
        width: 140px;
        height: 44px;
        transform: translateX(-50%);
        border-radius: 22px;
        text-align: center;
        background-color: #EF0923;
        line-height: 44px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}
.live_iframe {
    width: 100%;
    height: 200px;
}
</style>
